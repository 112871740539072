﻿.admin-nav {

    .level-0, level-1 {
        &__item {
            button, a {
                color: $white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .level-0 {
        &__item {
            &:not(:first-child) {
                margin-top: 25px;
            }

            button, a {
                @include font($font-semibold);
            }
        }
    }

    .level-1 {
        padding-left: 15px;

        &__item {
            &:not(:first-child) {
                margin-top: 5px;
            }

            button, a {
                @include font($font-regular);
            }
        }
    }
}
