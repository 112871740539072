﻿.admin-dashboard {
    @media (min-width: $breakpoint-md) {
        max-width: 70%
    }
}

.admin-nav {
    padding: 30px;
    height: 100%;
}

.admin-body {
    padding: 30px;
    height: 100%;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}