﻿.toggle {
    display: block;
    text-align: center;
    user-select: none;
}

.toggle--checkbox {
    display: none;
}

.toggle--btn {
    display: block;
    margin: 0 auto;
    font-size: 1.4em;
    transition: all 350ms ease-in;

    &:hover {
        cursor: pointer;
    }
}

.toggle--btn,
.toggle--checkbox,
.toggle--feature {
    &,
    &:before,
    &:after {
        //transition: all 250ms ease-in;
    }

    &:before,
    &:after {
        content: '';
        display: block;
    }
}



//specific
$toggleWidth: 65px;
$toggleHeight: 24px;
$toggleBtnSize: 18px;

.toggle--switch {

    & .toggle--btn {
        position: relative;
        width: $toggleWidth;
        height: $toggleHeight;
        text-transform: uppercase;
        color: $black;
        background-color: $white;


        &,
        &:before {
            border-radius: calc(#{$toggleHeight} / 2);
        }

        //contains the actual knob part of the button and the 'on' text
        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 3px;
            transform: translateY(-50%);
            width: $toggleBtnSize;
            height: $toggleBtnSize;
            background-image: image("icon-en.svg");
            background-size: cover;
            background-position: center;
            text-indent: -100%;
        }
    }

    & .toggle--feature {
        @include font-size($font-size-small);
        position: relative;
        display: block;
        overflow: hidden;
        height: $toggleHeight;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:before {
            content: attr(data-label-on);
            left: -60%;
        }

        &:after {
            content: attr(data-label-off);
            right: 16%;
        }
    }

    & .toggle--checkbox {
        &:checked {
            & + .toggle--btn {
                background-position: 0 0;

                &:before {
                    left: calc(100% - (#{$toggleBtnSize} + 3px));
                    background-image: image("icon-nl.svg");
                }

                & .toggle--feature {
                    &:before {
                        left: 20%;
                    }

                    &:after {
                        right: -60%;
                    }
                }
            }
        }
    }
}
