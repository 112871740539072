﻿/// -----------------------------------------------------------------------------
/// This file contains very basic styles.
/// -----------------------------------------------------------------------------

/// Set up a decent box model on the root element
html {
    box-sizing: border-box;
    font-size: 62.5%;
}

/// * Make all elements from the DOM inherit from the parent box-sizing
/// * Since `*` has a specificity of 0, it does not override the `html` value
/// * making all elements inheriting from the root box-sizing value
/// * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*::before,
*::after {
    box-sizing: inherit;
}


body {
    @include font($font-regular);
    @include font-size($font-size-regular);
    line-height: 1.2;
}


.page-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $brand-primary;
    background-image: image("bg-body.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.page-container {
    position: relative;
    min-height: 100vh;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: radial-gradient(circle at bottom left, rgba(1,38,53,1), rgba(1,38,53,0));
    }
}

.content-wrapper {
    padding-bottom: 105px; // footer height + footer marign + padding
    position: relative;

    @media (min-width: $breakpoint-md) {
        padding-bottom: 90px; // footer height + footer marign
    }
}

.main-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

[v-cloak] {
    display: none
}

.js-datetimepicker {
    background-color: white !important;
}