﻿// ***********
// FONT COLORS
// ***********

/// Generic
.color-white {
    color: $white !important;
}

.color-black {
    color: $black !important;
}

/// Theme
.color-brand-primary {
    color: $brand-primary !important;
}

.color-brand-secondary {
    color: $brand-secondary !important;
}

.color-brand-tertiary {
    color: $brand-tertiary !important;
}

/// Others
.color-dark-red {
    color: $dark-red !important;
}

// ***********
// BACKGROUND COLORS
// ***********

.bg-white{
    background-color: $white !important;
}

.bg-light {
    background-color: $light-grey !important;
}

.bg-brand-primary {
    background-color: $brand-primary !important;
}

.bg-brand-secondary {
    background-color: $brand-secondary !important;
}

.bg-brand-tertiary {
    background-color: $brand-tertiary !important;
}