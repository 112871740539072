﻿.divider {
    width: 70%;
    display: flex;
    justify-content: center;
    position: relative;

    .text {
        background-color: $light-grey;
        position: relative;
        z-index: 10;
        padding: 15px;
    }

    hr {
        margin: 0;
        padding: 0;
        border: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid rgba($black, 0.3);
        @include centre();
        z-index: 1;
    }
}
