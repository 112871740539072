﻿.insurance-companies {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    &__link {
        text-decoration: none;
    }

    &__logo {
        max-width: 90px;
        height: auto;
    }
}
