﻿.icon {
    fill: currentColor;

    &__chevron-left {
        transition: all .2s ease-out;
    }

    &__chevron-down {
        width: 13px;
        height: auto;
        transform: rotate(0deg);
        transition: transform .2s ease;

        &.rotate {
            transform: rotate(180deg);
        }
    }

    &__chevron-down-single {
        width: 13px;
        height: auto;
        transform: rotate(0deg);
        transition: transform .2s ease;

        &.rotate {
            transform: rotate(180deg);
        }
    }

    &__phone {
        width: 21px;
        height: auto;
    }
}
