﻿label, .form-text, .validation{
    padding: 0 10px;
}

.form-text {
    margin-bottom: 1rem;
}

.form-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

label{
    margin: 0;
    @include font($font-semibold);
    @include font-size($font-size-regular);
    line-height: 1;

    &.is-required{
        &:after{
            content: "*";
            color: $dark-red;
            margin-left: 3px;
        }
    }
}

.validation {
    display: inline-block;

    &.field-validation-error {
        @include font-size($font-size-small);
        font-style: italic;
        color: $dark-red;
    }

    &.field-validation-valid {
        display: none;
    }
}

.form-control {
    width: 100%;
    max-width: 100%;
    height: auto;
    @include font-size($font-size-regular);
    @include line-height(26);
    background-color: $white;
    padding: 5px 10px;
    margin: 0;
    border: 1px solid rgba($medium-grey, 1);
    border-radius: 4px;

    @include placeholder(rgba($brand-tertiary, 0.7), italic);

    &.input-validation-error {
        border-color: $dark-red;
    }
}
