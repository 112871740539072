﻿.font {
    &-light {
        @include font($font-light);
    }

    &-regular {
        @include font($font-regular);
    }

    &-semibold {
        @include font($font-semibold);
    }

    &-bold {
        @include font($font-bold);
    }
}


.text {
    &-tiny {
        @include font-size($font-size-tiny);
    }

    &-small {
        @include font-size($font-size-small);
    }

    &-regular {
        @include font-size($font-size-regular);
    }

    &-medium {
        @include font-size($font-size-medium);
    }
}