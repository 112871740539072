﻿h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @include font($font-bold);

    &.regular {
        @include font($font-regular);
    }

    &.bold {
        @include font($font-bold);
    }

    &.semibold {
        @include font($font-semibold);
    }

    &.light {
        @include font($font-light);
    }
}

h1, .h1 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 20px, 24px);
}

h2, .h2 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 18px, 22px);
}

h3, .h3 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 20px);
}

h4, .h4 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 18px);
}

h5, .h5 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 16px);
}

h6, .h6 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 14px, 16px);
}

em, .em {
    font-style: italic;
}

strong, .strong {
    @include font($font-bold);
}
