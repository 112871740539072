﻿.responsive-table {
    width: 100%;
    overflow: hidden;

    tr {
        border-color: lighten(#34495E, 10%);
    }

    th, td:before {
        color: $black;
    }


    thead {
        tr {
            border-bottom: 1px solid #ddd;
        }
    }

    tbody {

        .td {
            &__orderid {
            }

            &__scope {
            }

            &__created {
            }

            &__price {
            }

            &__state {
            }

            &__view, &__archive, &__edit {
                .btn {
                    @include font($font-regular);
                    color: $black;
                    line-height: 1;
                    text-decoration: underline;

                    &:hover {
                        color: darken($brand-primary, 10%);
                    }
                }
            }

            &__view {
            }

            &__archive {
            }
        }
    }

    th {
        display: none;
    }

    th, td:before {
        @include font($font-semibold);
    }

    td {
        display: block;
        padding: 5px;

        &:before {
            content: attr(data-th);
            width: 25%;
            display: inline-block;
        }
    }

    th, td {
        text-align: left;
        padding: 5px;
    }


    &.striped {
        tbody {
            tr {
                &:nth-child(2n) {
                    background-color: rgba($brand-primary, .2);
                }
            }
        }
    }

    @media (min-width: $breakpoint-lg) {
        td {
            &:before {
                display: none;
            }
        }

        th, td {
            display: table-cell;
        }
    }

    &-split {
        th, td {
            width: 50%;
        }
    }
}
