﻿.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($black, .5);
    transition: opacity .3s ease;
}

.modal {
    &__container {
        max-width: 500px;
        margin: 0px auto;
        padding: 30px;
        background-color: $white;
        box-shadow: 0 2px 8px rgba($black, .33);
        transform: scale(1);
        transition: all .3s ease;
    }


    &__header {
        margin-bottom: 30px;

        .title {
            color: $brand-primary;
        }
    }

    &__body {
        margin-bottom: 40px;
    }

    &__footer {
    }
}

.modal-enter-active{
    transition: all .3s ease;
}

.modal-enter,
.modal-enter-active,
.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal__container,
.modal-enter-active .modal__container,
.modal-leave-active .modal__container {
    transform: scale(1.1);
}
