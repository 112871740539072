﻿.info {
    position: relative;
    overflow: hidden;
    max-height: 400px;
    background: #eee;

    &__inner {
        padding: 15px;
    }
}

.other {
    background-color: lightgreen;
    padding: 5px;
}

.slide-enter-active,
.slide-leave-active {
    max-height: 400px;
    transition: all 0.3s ease-out;
}

.slide-enter,
.slide-leave-to { 
    max-height: 0px;
    opacity: 0;
}

.toggleSlide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.toggleSlide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.toggleSlide-enter-to, .toggleSlide-leave {
    max-height: 100px;
    overflow: hidden;
}

.toggleSlide-enter, .toggleSlide-leave-to {
    overflow: hidden;
    max-height: 0;
}