﻿.main-footer {
    height: 70px;
    margin-top: 20px;
    padding-top: 15px;
    background-color: $brand-tertiary;
    color: $white;

    .footer-nav {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &__item {
            &:not(:last-child) {
                &:after {
                    content: "|";
                    display: inline-flex;
                    margin: 5px;
                }
            }
        }

        &__link {
            color: inherit;

            &:hover, &.active {
                text-decoration: underline;
            }
        }
    }

    @media (min-width: $breakpoint-md) {
        padding-top: 0px;

        .footer-nav {
            justify-content: flex-end;

            &__item {
                &:not(:last-child) {
                    &:after {
                        content: "|";
                        display: inline-flex;
                        margin: 0 15px;
                    }
                }
            }
        }
    }
}
