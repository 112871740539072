﻿a{
    color: $brand-primary;
}

.a {

    &__back {
        background-color: transparent;
        border: 0;
        display: inline-flex;
        align-items: center;
        position: relative;
        padding-left: 19px; // icon width + 5px marign
        color: $white;
        text-transform: uppercase;
        transition: all .2s ease-out;

        .icon {
            width: 14px;
            margin-right: 5px;
            position: absolute;
            left: 0;
        }

        &:hover {
            color: $white;
            text-decoration: none;
            padding-left: 10px;

            .icon {
                left: -5px;
            }
        }
    }
}