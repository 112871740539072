﻿.pagination {
    margin-top: 25px;

    .page-item {
        .page-link {
            padding: 5px 10px;
            color: $brand-primary;
        }

        &.disabled {
            .page-link {
                color: $medium-grey;
            }
        }
    }

    .page-item-select {
        margin: 0px 2px;
        color: $brand-primary;
    }

    .page-link-option {
        color: $brand-primary;
    }
}
