﻿.main-nav {
    background-color: rgba($brand-primary, 1);
    box-shadow: 0 2px 15px rgba(0,0,0,.3);

    @media (min-width: $breakpoint-md) {
        background-color: transparent;
        box-shadow: none;
    }
}
.navbar {
    height: 64px;
    padding: 0;
    margin-bottom: 40px;

    .navbar-brand {
        width: 105px;
        height: 100%;
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $brand-primary;
    }

    .user-dashboard {
        .list-inline {
            margin-bottom: 0;
            .list-inline-item {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        .btn {
            color: $white;

            &.is-active{
                text-decoration: underline;
            }
        }
    }

    &__controls{
        display: flex;
        align-items: center;
    }

    @media (min-width: $breakpoint-md) {
        height: 112px;
        margin-bottom: 40px;
        justify-content: space-between;

        .navbar-brand {
            width: 112px;
            padding: 0 8px;
        }

        .user-dashboard{
            padding-left: 25px;
        }
    }
}
