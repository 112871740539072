﻿.user-dashboard-detail{

    .state-indicator{
        position: absolute;
        top: 15px;
        right: 15px;
        margin: 0;
    }

    .order-details{
        table{
            tr{
                td{
                    &:before{
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .user-dashboard-controls{}
}