﻿.btn {
    @include font($font-semibold);
    @include font-size($font-size-medium);
    border-radius: 0;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 17px;
    padding-right: 17px;
    @include line-height(40);
    border: 0;

    &:focus, &.focus {
        outline: 0;
    }

    &__primary, &__secondary, &__tertiary {
        transition: all .2s ease;
    }

    &__primary {
        background-color: $brand-primary;
        color: $brand-secondary;

        &:hover {
            background-color: lighten($brand-primary, 10%);
            color: $brand-secondary;
        }
    }

    &__secondary {
        background-color: $brand-secondary;
        color: $brand-primary;

        &:hover {
            background-color: lighten($brand-secondary, 10%);
            color: $brand-primary;
        }
    }

    &__tertiary {
        background-color: $brand-tertiary;
        color: $white;

        &:hover {
            background-color: lighten($brand-tertiary, 10%);
            color: $white;
        }
    }

    &__text {
        padding: 0;
        @include font-size($font-size-regular);
        color: $brand-primary;
        outline: 0;

        &:active, &:focus {
            outline: 0;
            box-shadow: none;
        }

        &:hover {
            color: darken($brand-primary, 10%);
        }
    }

    &__circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $light-grey;
        border: 1px solid rgba($medium-grey, 1);
        padding: 10px;
        margin-left: 15px;
        transform: rotate(0deg);
        transition: all .2s ease;

        span {
            width: 75%;
            height: 2px;
            background-color: $brand-primary;
            position: absolute;
            top: 50%;
            left: 50%;

            &:first-child {
                transform: translate(-50%, -50%) rotate(0deg);
                transition: transform .2s ease .2s;
            }

            &:last-child {
                transform: translate(-50%, -50%) rotate(90deg);
                transition: transform .2s ease .2s;
            }
        }

        &.is-active {
            transform: rotate(45deg);

            span {
                &:first-child {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:last-child {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            width: 30px;
            height: 30px;
        }
    }

    &__tab {
        background-color: $brand-primary;
        color: $light-grey;
        padding: 0 15px;
        @include font-size($font-size-regular);
        outline: 0;
        transition: all .2s ease;

        &:active, &:focus {
            outline: 0;
            box-shadow: none;
        }

        &:hover {
            color: darken($light-grey, 10%);
        }

        &.is-active {
            background-color: $light-grey;
            color: $brand-primary;
        }
    }
}
